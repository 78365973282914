import React, { createRef } from "react";
import { createPortal } from "react-dom";

class IframePortal extends React.Component {
  constructor(props) {
    super(props);
    this.iframeRef = createRef();
    this.state = { contentRef: null };
  }

  componentDidMount() {
    const iframeDocument =
      this.iframeRef.current.contentDocument ||
      this.iframeRef.current.contentWindow.document;
    this.setState({ contentRef: iframeDocument.body });
  }

  render() {
    console.log("@@portal:  ==>", this.props.src);
    return (
      <iframe
        title={this.props.title}
        ref={this.iframeRef}
        src={this.props.src}
        style={{ width: "100%", height: "500px", border: "none" }}
      >
        {this.state.contentRef && createPortal(<div />, this.state.contentRef)}
      </iframe>
    );
  }
}

export default IframePortal;